import React, { useEffect, useRef } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";

import { Col, Container, Row } from "react-bootstrap";

import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import ScrollToTarget from "../components/elements/ScrollToTarget";
import Anchor from "../components/elements/Anchor";
import AnchorPrismic from "../components/elements/AnchorPrismic";
// import AppsIcons from "../components/elements/AppsIcons";
import { constructActiveDoc } from "../helpers";

import "../styles/pages/diagonismos-smart.scss";

const DiagonismosSmart = ({ data }) => {
  if (!data) return null;
  const scrollTarget = useRef(null);

  // useEffect(() => {
  //   document.getElementById("diagonismos_form").innerHTML = "";
  //   const script = document.createElement("script");
  //   script.src = "https://js.hsforms.net/forms/v2.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  //   script.addEventListener("load", () => {
  //     window.hbspt.forms.create({
  //       region: "na1",
  //       portalId: "9215795",
  //       formId: "8600a2c9-51ad-45e4-a0b4-51e6cbd1d2d9",
  //       target: "#diagonismos_form",
  //     });
  //   });
  // }, []);

  const { settings, page } = data;
  const {
    title,
    image,
    image_mobile: imageMobile,
    // step_1: step1,
    // step_2: step2,
    // step_2_image: step2Image,
    // step_text: stepText,
    complete,
    subtext,
    subtitle,
    text,
    tos_link: tosLink,
    tos_link_label: tosLinkLabel,
  } = page.data;

  return (
    <>
      <Seo page={page} settings={settings} />
      <Layout
        settings={settings}
        activeDocMeta={constructActiveDoc(page)}
        headerClass="position-sticky top-0"
      >
        <div
          className="diagonismos-smart pb-6 text-dark"
          style={{ "--contest-gray": "#D6D1CE" }}
        >
          <section className="diagonismos-smart__header d-xl-grid mb-xl-6">
            <figure className="diagonismos-smart__header-media">
              {image.gatsbyImageData && (
                <GatsbyImage
                  image={image.gatsbyImageData}
                  alt={image.alt || title || ""}
                  className={
                    imageMobile?.gatsbyImageData ? "d-none d-md-block" : null
                  }
                />
              )}
              {imageMobile?.gatsbyImageData && (
                <GatsbyImage
                  image={imageMobile.gatsbyImageData}
                  alt={imageMobile.alt || title || ""}
                  className="d-md-none"
                />
              )}
            </figure>
            <div className="diagonismos-smart__header-content position-relative py-5 py-xl-6 px-3 text-center">
              <h1 className="fw-bold">{title}</h1>
              <h2 className="h3 fw-bold text-primary">{subtitle}</h2>
              <ScrollToTarget target={scrollTarget} className="mt-3" />
            </div>
          </section>
          <div ref={scrollTarget} aria-hidden="true" />
          <Container className="diagonismos-smart__subtext mb-6 text-center">
            <div className="h3 mb-5">
              <RichText
                render={text.richText}
                serializeHyperlink={AnchorPrismic}
              />
            </div>
            <p className="h3 fw-bold">{subtext}</p>
            <Anchor
              href={tosLink.url}
              target={tosLink.target}
              className="text-primary"
            >
              {tosLinkLabel}
            </Anchor>
          </Container>
          <hr />
          <Container fluid>
            <Row>
              {/* <Col xl={6} className="col-xxxl-5 offset-xxxl-1">
                <section className="diagonismos-smart__step mt-5 ms-1 px-3 px-sm-6 pb-4 pt-6 pt-sm-7">
                  <header>
                    <h2 className="h1 d-inline-block m-0 pe-3 pb-2 pb-sm-3 text-primary">
                      {stepText} 1
                    </h2>
                    <p className="h3 m-0 pb-3">{step1}</p>
                  </header>
                  <div className="mt-sm-5" id="diagonismos_form" />
                </section>
              </Col>
              <Col xl={6} className="col-xxxl-5">
                <section className="diagonismos-smart__step mt-5 ms-1 px-3 px-sm-6 pt-6 pt-sm-7">
                  <header>
                    <h2 className="h1 d-inline-block m-0 pe-3 pb-2 pb-sm-3 text-primary">
                      {stepText} 2
                    </h2>
                    <p className="h3 m-0 pb-3">{step2}</p>
                  </header>
                  <AppsIcons
                    settings={settings}
                    size="large"
                    className="justify-content-center py-4 py-sm-5"
                  />
                  <div className="diagonismos-smart__step-image text-center">
                    <GatsbyImage
                      image={step2Image.gatsbyImageData}
                      alt={step2Image.alt || ""}
                    />
                  </div>
                </section>
              </Col> */}
              <Col xl={{ span: 6, offset: 3 }}>
                <p className="h3 mt-6 text-center">{complete}</p>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query diagonismosSmartQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicDiagonismosSmartFortwo(lang: { eq: $lang }) {
      ...prismicDiagonismosSmartFortwoFragment
    }
  }
`;

export default DiagonismosSmart;
